(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': { 
      init: function() {
        // <!--- PUT STUFF IN THIS FUNCTION !!! JavaScript to be fired on all pages
        
        /* ----------------------------------------------------
          Notifications banner placement */
        function notifications_banner() {
          if($("#notifications-banner").length > 0) {
            if($("#notifications-banner").hasClass('fixed-bottom')) {
              $('body').css('padding-bottom', $("#notifications-banner").outerHeight());
            }
            else {
              $('body').css('padding-top', $("#notifications-banner").outerHeight());
            }
          }
        }
        $(window).resize(function() { notifications_banner(); }); // onresize
        $(window).load(function(){ notifications_banner(); }); // onload     
        /* End notifications banner 
           ----------------------------------------------------*/
        
        $(window).on("load", function (e) {
          $('a[href^="#"]:not([role="tab"]').on('click',function (e) {
              e.preventDefault();

              var target = this.hash;
              var $target = $(target);

              if($target.length>0) {

                $('html, body').stop().animate({
                    'scrollTop': $target.offset().top - 120
                }, 900, 'swing', function () {
                    window.location.hash = target;
                });
              }
          });

        }); 

        // Menu
        var $navbar_toggler = $('#navbar-toggler'); 

       // $('.offcanvas-collapse').css('padding-top', $('#header').height());

        if($('body').hasClass('logged-in')) {
    //      $('.offcanvas-collapse').css('padding-top', $('#header').height() + $('#wpadminbar').height());
        }
        $('[data-toggle="offcanvas"]').on('click tap press', function (e) {
          $('.offcanvas-collapse').toggleClass('open');
          $('#header').toggleClass('open');
          $('body').toggleClass('open');
          $navbar_toggler.toggleClass('collapsed');
        });


        $('.slick').slick({
          centerMode: true,
          centerPadding: '0',
          slidesToShow: 5,
          responsive: [
            {
              breakpoint: 1000,
              settings: {
                arrows: true,
                centerMode: true,
                centerPadding: '40px',
                slidesToShow: 3
              }
            },
            {
              breakpoint: 768,
              settings: {
                arrows: true,
                centerMode: true,
                centerPadding: '40px',
                slidesToShow: 1
              }
            }
          ]
        });

         $('.testimonial-slider').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            dots: true,
            speed: 1200,
            autoplay: true,
            fade: true,
            autoplaySpeed: 4000
          });


         $('.gallery').slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
          fade: false,
          asNavFor: '.gallery-nav'
        });
         
        $('.gallery-nav').slick({
          slidesToShow: 5,
          slidesToScroll: 1,
          asNavFor: '.gallery',
          dots: false,
          arrows: false,
          centerMode: true,
          focusOnSelect: true
        });

        // Careers PAnel
        var $careerslaunch = $('.careers-link');
        var $careersclose = $('#home-button');
        var $careerspanel = $('#careers-section');
       // var $careersclose = $('#careers-close');

        $($careerslaunch).on('click tap press', function (e) {
          e.preventDefault();
          $($careerspanel).toggleClass('open');
          $($careersclose).toggleClass('open');
          $($careerslaunch).toggleClass('open');
          $('body').toggleClass('open');

         // setTimeout(function(){ $("#careers-video")[0].src += "&autoplay=1"; }, 2000);
          
         // $('.btn-skip').css(opacity:"0").delay(1000).fadeIn(400);

        });
        // $($careersclose).on('click tap press', function (e) {
        //     $($careerspanel).toggleClass('open');
        //     $('body').toggleClass('open');
        // });


        $( ".wp-block-media-text__media" ).prepend( '<span class="img-animate ani2"></span>' );

        // MAtch Height


        $('.inner-copy').matchHeight(); 

        // ScrollReveal
        // window.sr = new ScrollReveal();
        // sr.reveal('.reveal');
        // // Reveal in a ripple efffect
        // sr.reveal('.my-class', { duration: 800 }, 70);

        var controller = new ScrollMagic.Controller({globalSceneOptions: {}});

          // build scenes
        new ScrollMagic.Scene({triggerElement: "#scrolledtrigger" })
                  .setClassToggle("#header", "scrolled") // add class toggle
                 // .addIndicators() // add indicators (requires plugin)
                  .addTo(controller);

        new ScrollMagic.Scene({triggerElement: "#above-footer" })
                  .setClassToggle(".launch-careers", "footer") // add class toggle
                 // .addIndicators() // add indicators (requires plugin)
                  .addTo(controller);

        var controller2 = new ScrollMagic.Controller({globalSceneOptions: {}});
        
          if($('.section-hero1').length) {
              var scene = new ScrollMagic.Scene({
                        triggerElement: ".section-hero1",
                       // reverse:false
              })
              .setTween(".section-hero1 .ani2", 1.5, {x:"220%", ease:Power3.easeInOut}) // trigger a TweenMax.to tween
                   //  .addIndicators({name: "1 (duration: 0)"}) // add indicators (requires plugin)
              .addTo(controller2);
          }
          if($('.section-hero2').length) {
                var scene = new ScrollMagic.Scene({
                      triggerElement: ".section-hero2",
                   //   reverse:false
                    })
                    .setTween(".section-hero2 .ani2", 1.5, {x:"220%", ease:Power3.easeInOut}) // trigger a TweenMax.to tween
                   // .addIndicators({name: "1 (duration: 0)"}) // add indicators (requires plugin)
                    .addTo(controller2);
          }
          if($('.section-hero3').length) {
                var scene = new ScrollMagic.Scene({
                      triggerElement: ".section-hero3",
                   //   reverse:false
                    })
                    .setTween(".section-hero3 .ani2", 1.5, {x:"220%", ease:Power3.easeInOut}) // trigger a TweenMax.to tween
                   // .addIndicators({name: "1 (duration: 0)"}) // add indicators (requires plugin)
                    .addTo(controller2);
          }



          // Video

          var slideWrapper = $(".video-slider"),
              iframes = slideWrapper.find('.embed-player'),
              lazyImages = slideWrapper.find('.slide-image'),
              lazyCounter = 0;

          // POST commands to YouTube or Vimeo API
          function postMessageToPlayer(player, command){
            if (player == null || command == null) return;
            player.contentWindow.postMessage(JSON.stringify(command), "*");
          }

          // When the slide is changing
          function playPauseVideo(slick, control){
            var currentSlide, slideType, startTime, player, video;

            currentSlide = slick.find(".slick-current");
            slideType = currentSlide.attr("class").split(" ")[1];
            player = currentSlide.find("iframe").get(0);
            startTime = currentSlide.data("video-start");

            if (slideType === "vimeo") {
              switch (control) {
                case "play":
                  if ((startTime != null && startTime > 0 ) && !currentSlide.hasClass('started')) {
                    currentSlide.addClass('started');
                    postMessageToPlayer(player, {
                      "method": "setCurrentTime",
                      "value" : startTime
                    });
                  }
                  postMessageToPlayer(player, {
                    "method": "play",
                    "value" : 0
                  });
                  break;
                case "pause":
                  postMessageToPlayer(player, {
                    "method": "pause",
                    "value": 1
                  });
                  break;
              }
            } else if (slideType === "youtube") {
              switch (control) {
                case "play":
                  postMessageToPlayer(player, {
                    "event": "command",
                    "func": "mute"
                  });
                  postMessageToPlayer(player, {
                    "event": "command",
                    "func": "playVideo"
                  });
                  break;
                case "pause":
                  postMessageToPlayer(player, {
                    "event": "command",
                    "func": "pauseVideo"
                  });
                  break;
              }
            } else if (slideType === "video") {
              video = currentSlide.children("video").get(0);
              if (video != null) {
                if (control === "play"){
                  video.play();
                } else {
                  video.pause();
                }
              }
            }
          }

          // Resize player
          // function resizePlayer(iframes, ratio) {
          //   if (!iframes[0]) return;
          //   var win = $(".main-slider"),
          //       width = win.width(),
          //       playerWidth,
          //       height = win.height(),
          //       playerHeight,
          //       ratio = ratio || 16/9;

          //   iframes.each(function(){
          //     var current = $(this);
          //     if (width / ratio < height) {
          //       playerWidth = Math.ceil(height * ratio);
          //       current.width(playerWidth).height(height).css({
          //         left: (width - playerWidth) / 2,
          //          top: 0
          //         });
          //     } else {
          //       playerHeight = Math.ceil(width / ratio);
          //       current.width(width).height(playerHeight).css({
          //         left: 0,
          //         top: (height - playerHeight) / 2
          //       });
          //     }
          //   });
          // }

          // DOM Ready
          $(function() {
            // Initialize
            slideWrapper.on("init", function(slick){
              slick = $(slick.currentTarget);
              setTimeout(function(){
               // playPauseVideo(slick,"play");
              }, 1000);
              //resizePlayer(iframes, 16/9);
            });
            slideWrapper.on("beforeChange", function(event, slick) {
              slick = $(slick.$slider);
              playPauseVideo(slick,"pause");
            });
            slideWrapper.on("afterChange", function(event, slick) {
              slick = $(slick.$slider);
             // playPauseVideo(slick,"play");
            });
            slideWrapper.on("lazyLoaded", function(event, slick, image, imageSource) {
              lazyCounter++;
              if (lazyCounter === lazyImages.length){
                lazyImages.addClass('show');
                // slideWrapper.slick("slickPlay");
              }
            });

            //start the slider
            slideWrapper.slick({
              // fade:true,
              centerMode: true,
              centerPadding: '20px',
              slidesToShow: 3,
              variableWidth: true,
              autoplaySpeed:4000,
              lazyLoad:"progressive",
              speed:600,
              arrows:true,
              dots:true,
             // cssEase:"cubic-bezier(0.87, 0.03, 0.41, 0.9)"
            });
          });

          // Resize event
          $(window).on("resize.slickVideoPlayer", function(){  
            resizePlayer(iframes, 16/9);
          });
        

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        
        // Stick the footer to the bottom of the screen if the content isn't tall enough
        function stickyFooter() {
          var $footer = $('#footer');
          if($footer.length<=0) {
            return;
          }

          $footer.removeClass('fixed-bottom');

          if(window.innerHeight > ($footer.height() + $footer.offset().top)) {
            $footer.addClass('fixed-bottom');
          }
          else {
            $footer.removeClass('fixed-bottom');
          }
        }       

        $( window ).resize(function() {
          stickyFooter();
        });

        // If window height is MORE than (footer height + footer top position) THEN make footer 'sticky' 
        stickyFooter();


        /*
         * Replace all SVG images with inline SVG
         */
        $('img.svg').each(function(){
            var $img     = jQuery(this);
            var imgID    = $img.attr('id');
            var imgClass = $img.attr('class');
            var imgURL   = $img.attr('src');

            $.get(imgURL, function(data) {
                // Get the SVG tag, ignore the rest
                var $svg = jQuery(data).find('svg');

                // Add replaced image's ID to the new SVG
                if(typeof imgID !== 'undefined') {
                    $svg = $svg.attr('id', imgID);
                }
                // Add replaced image's classes to the new SVG
                if(typeof imgClass !== 'undefined') {
                    $svg = $svg.attr('class', imgClass+' replaced-svg');
                }

                // Remove any invalid XML tags as per http://validator.w3.org
                $svg = $svg.removeAttr('xmlns:a');

                // Check if the viewport is set, if the viewport is not set the SVG wont't scale.
                if(!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
                    $svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'));
                }

                // Replace image with new SVG
                $img.replaceWith($svg);

            }, 'xml');

        });

      }
    },
    // // Home page
    'home': {
      init: function() {

          $('.slideshow').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            dots: false,
            speed: 1200,
            autoplay: true,
            fade: true,
            autoplaySpeed: 4000
          });
        
      }
    }

  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
